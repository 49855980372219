@font-face {
  font-family: "MountainsofChristmas-Bold";
  src: url("../src/fonts/Xmas_Show.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "MountainsofChristmas-Regular";
  src: url("../src/fonts/Xmas_Show.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "MountainsofChristmas-Bold", sans-serif;
  /* cursor: url(http://www.rw-designer.com/cursor-extern.php?id=76232), auto !important; */
  /*colors*/
  --color1: #d6d6ce;
  --color2: rgb(0, 0, 0);
  --color3: orange;
  --color4: rgb(154, 38, 38);

  --text-focus: rgb(255, 170, 12);
  --text-secondary: white;

  background: var(--color1);
  background-image: url(./Images/bg1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  /* overflow: hidden; */
  color: rgb(130, 22, 109) !important;
}

h1 {
  font-family: "MountainsofChristmas-Bold", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

/*========Loading Section========*/

.LoadingScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 97vh;
}


.Phrase1 {
  /* height: 200px; */
  opacity: 0;
  font-size: 90px;
  margin: 0px;
  animation: fadeLogo 6s linear;
}

.Phrase2 {
  /* height: 100px; */
  font-size: 90px;
  margin: 0px;
  opacity: 0;
  animation: fadeMotto 6s linear;
}

@keyframes fadeNeutral {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
    /* transform: translateX(0px); */
  }
  50% {
    /* opacity: 1; */
    /* transform: translateX(-100px); */
  }
  70% {
    opacity: 1;
    /* transform: translateX(-100px); */
  }
  90% {
    opacity: 0;
  }
}

@keyframes fadeLogo {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  20% {
    opacity: 1;
    /* transform: translateX(0px); */
  }
  50% {
    /* opacity: 1; */
    /* transform: translateX(-100px); */
  }
  70% {
    opacity: 1;
    /* transform: translateX(-100px); */
  }
  90% {
    opacity: 0;
    transform: translateX(-100px);
  }
}

@keyframes fadeMotto {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
    transform: translateX(150px);
  }
  40% {
    opacity: 1;
    /* transform: translateX(100px); */
  }
  70% {
    opacity: 1;
  }
  90% {
    opacity: 0;
    transform: translateX(100px);
  }
}

/*==========General Site Styles==========*/

.App {
  /* background-image: url("./Images/bt1.png"); */
  /* height: 97vh; */
  /* background-position: center; */
  /* background-size: cover; */
  /* z-index: 50; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*===========Media  Queries============*/

@media screen and (max-width: 1400px) {
  .Phrase1 {
    font-size: 30px;
    animation: fadeNeutral 6s linear;
    text-align: center;
  }

  .Phrase2 {
    font-size: 30px;
    text-align: center;
    animation: fadeNeutral 6s linear;
  }
}
