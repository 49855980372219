.C2Super {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(130, 22, 109);
}

.C2Container {
  margin-top: 50px;
  margin-bottom: 150px;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.C2Title h2 {
  font-size: 52px;
  color: rgb(130, 22, 109);
  margin-bottom: 0px;
}

.C2Title h3 {
  margin-top: 0px;
  font-size: 32px;
  color: rgb(130, 22, 109);
}

.C2SubContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.C2Image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.C2Image img {
  width: 350px;
}

.C2Text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.C2Text p {
  width: 100%;
  font-size: 22px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 0px;
}

.C2Text h4 {
  width: 90%;
  margin-top: 0px;
  font-size: 18px;
  text-align: right;
}

.distribution{
  width: 50%;
}

/*===========Media  Queries============*/

@media screen and (max-width: 1024px) {
  .C2SubContainer {
    flex-direction: column;
  }

  .C2Image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .C2Image img {
    width: 100%;
    max-width: 430px;
  }

  .C2Title h2 {
    font-size: 32px;
  }

  .C2Title h3 {
    font-size: 22px;
  }

  /* ... Tu CSS existente ... */

  .CarouselContainer {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Estilo adicional para el carrusel */
  .CarouselContainer .slick-slider {
    width: 80%;
  }

  .CarouselContainer .slick-prev,
  .CarouselContainer .slick-next {
    font-size: 24px;
    color: brown;
  }

  .CarouselContainer .slick-dots {
    bottom: -30px;
  }

  .CarouselContainer .slick-dots li {
    margin: 0 4px;
  }

  .CarouselContainer .slick-dots button {
    font-size: 12px;
    color: brown;
  }

  /* Estilo para los fondos de color en el carrusel */
  .slick-slide {
    background-size: cover;
  }
}
