.PSuper {
  position: fixed;
  right: 5%;
  bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 137px;
  width: 270px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.PlayVolumeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.FrameImg {
  position: absolute;
  height: 110px;
  z-index: -1;
}

.PlayPauseBtn img {
  height: 50px;
}

.VolumeControl {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

#volume {
}

.VolumeControl img {
  height: 30px;
}

.NextPrevBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}

.NextPrevBtns button {
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
}

.control {
  height: 30px;
}

@media screen and (max-width: 1150px) {
  .PSuper {
    height: 67px;
    width: 135px;
    right: 40%;
    margin: 20px;
    /* display: none; */
  }
}
