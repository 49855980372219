.FSuper {
  background-color: rgb(130, 22, 109);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Fcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 70px;
  background-image: url(../../Images/square_border.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  /* border: 2px red solid; */
}

.FL img {
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.FT {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100px;

  /* border: solid 1px red; */
}

.FT img {
  height: 35px;
}

.Fcontainer p {
  color: antiquewhite;
}

/*===========Media  Queries============*/

@media screen and (max-width: 500px) {
  .FL img {
    height: auto;
    width: 80%;
  }
}
