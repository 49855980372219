.HSuper {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.HMainLogo {
  /* height: 600px; */
}

.HSubLogo {
  height: 100px;
  transform: translateX(600px);
}

/*===========Media  Queries============*/

@media screen and (max-width: 1150px) {
  .HMainLogo {
    height: auto;
    width: 90%;
  }
}
