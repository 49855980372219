.C1Super {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(130, 22, 109);
}

.C1Container {
  margin-top: 50px;
  margin-bottom: 150px;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.C1Title h2 {
  font-size: 52px;
  color: rgb(130, 22, 109);
  margin-bottom: 0px;
}

.C1Title h3 {
  margin-top: 0px;
  font-size: 32px;
  color: rgb(130, 22, 109);
}

.C1SubContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.C1Image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.C1Image img {
  width: 350px;

}

.C1Text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.C1Text p {
  width: 100%;
  font-size: 22px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 0px;
}

.C1Text h4 {
  width: 90%;
  margin-top: 0px;
  font-size: 18px;
  text-align: right;
}

/*===========Media  Queries============*/

@media screen and (max-width: 1024px) {
  .C1SubContainer {
    flex-direction: column-reverse;
  }

  .C1Image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .C1Image img {
    width: 100%;
    max-width: 430px;
  }

  .C1Title h2 {
    font-size: 32px;
  }

  .C1Title h3 {
    font-size: 22px;
  }
}
